import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { isLocalizedString } from '@ads-bread/shared/bread/codecs';
import { useCloseModal } from '../lib/hooks/useCloseModal';
import { classNames } from '../lib/classNames';
import { X } from './svgs/X';
import { useMerchantConfig } from './MerchantContext';
import { useMerchantDetails, useSDKOptions } from './XPropsContext';
import { LanguageToggle } from './LanguageToggle';
import { useAppConfig } from './AppConfigContext';
import { useLocale } from './IntlContext';
import { Button } from './Button';

type Props = {
  setShowFAQs: (x: boolean) => void;
};

export const Header: FunctionComponent<Props> = ({ setShowFAQs }) => {
  const { closeModalByContext } = useCloseModal();
  const { isEmbedded } = useSDKOptions();
  const { logoUrl } = useMerchantConfig();
  const { linkUrls, defaultMerchantConfig, tenantDisplayName, locales } =
    useAppConfig();
  const { locale } = useLocale();
  const { name } = useMerchantDetails();

  const handleShowFAQ = () => {
    if (!linkUrls || !linkUrls['faq']) {
      setShowFAQs(true);
    } else {
      const url = linkUrls['faq'][locale];
      window.open(url);
    }
  };
  const DEFAULT_LOGO_URL = isLocalizedString(defaultMerchantConfig.logoUrl)
    ? defaultMerchantConfig.logoUrl[locale]
    : defaultMerchantConfig.logoUrl;
  const customLogoURL = isLocalizedString(logoUrl) ? logoUrl[locale] : logoUrl;
  const localizedLogoURL = customLogoURL || DEFAULT_LOGO_URL;

  const logoAltText = `${
    customLogoURL === defaultMerchantConfig.logoUrl
      ? tenantDisplayName[locale]
      : name || 'Merchant'
  }`;

  return (
    <div className="flex mb-6 space-x-6 justify-between">
      <div className="flex-1 items-center max-w-[52%]">
        <img src={localizedLogoURL} className="w-15 h-10" alt={logoAltText} />
      </div>
      <div className="flex gap-5">
        <LanguageToggle />
        <Button
          theme="text"
          className={classNames(
            'text-lg font-extrabold self-center',
            locales.length < 2 ? '!text-blackberry' : 'text-theme-primary'
          )}
          onClick={handleShowFAQ}
          testID="show-faq"
        >
          <FormattedMessage defaultMessage="FAQs" description="Link to FAQs" />
        </Button>
        {closeModalByContext && !isEmbedded && (
          <button
            onClick={() => closeModalByContext()}
            data-test-id="close-faq-button"
            aria-label="Close"
            className="self-center"
          >
            <X
              className="w-4 h-4 filter hover:drop-shadow-lg text-dark-blue-grey"
              aria-hidden="true"
            />
          </button>
        )}
      </div>
    </div>
  );
};
