import { createMachine } from 'xstate';
import {
  Application,
  Buyer,
  IDVerificationEvaluationResponse,
  Offer,
  Order,
  PaymentAgreementDocument,
  VirtualCardIssuanceResponse,
} from '@ads-bread/shared/bread/codecs';
import { HandlerResponse } from '../handlers/base';

export type IDVerification = {
  frontImageData: Blob | null;
  backImageData: Blob | null;
  isIDVerified: boolean;
};

export interface ApplicationMachineContext {
  application: Application | null;
  selectedOffer: Offer | null;
  paymentAgreementDocument: PaymentAgreementDocument | null;
  idVerification: IDVerification;
}

/**
 * Events - event send type definitions
 */
export type SendGetApplication = {
  type: 'SEND_GET_APPLICATION';
  applicationID: string;
  resolve: (applicationRes: HandlerResponse<Application>) => void;
  reject: () => void;
};

export type SendGetLatestApplication = {
  type: 'SEND_GET_LATEST_APPLICATION';
  resolve: (applicationRes: HandlerResponse<Application | null>) => void;
  reject: () => void;
};

export type SendCreateApplication = {
  type: 'SEND_CREATE_APPLICATION';
};

export interface ReCreateApplicationOptions {
  order?: Order;
}
export type SendReCreateApplication = {
  type: 'SEND_RECREATE_APPLICATION';
  options: ReCreateApplicationOptions;
  resolve: (applicationRes: HandlerResponse<Application>) => void;
  reject: () => void;
};

export interface ApplicationCheckoutOptions {
  buyer: Buyer;
  shippingContactID: string;
  iovationBlackbox?: string | null;
  paymentMethodID?: string;
  isSplitPay?: boolean;
}
export type SendApplicationCheckout = {
  type: 'SEND_APPLICATION_CHECKOUT';
  options: ApplicationCheckoutOptions;
  resolve: (applicationRes: HandlerResponse<Application>) => void;
  reject: () => void;
};

export interface ApplicationPrepareCheckoutOptions {
  iovationBlackbox?: string | null;
  paymentMethodID?: string | null;
  isSplitPay?: boolean;
}
export type SendApplicationPrepareCheckout = {
  type: 'SEND_APPLICATION_PREPARE_CHECKOUT';
  options: ApplicationPrepareCheckoutOptions;
  resolve: (applicationRes: HandlerResponse<Application>) => void;
  reject: () => void;
};

export interface SendEvaluateIDVerificationImagesOptions {
  buyer: Buyer;
}
export type SendEvaluateIDVerificationImages = {
  type: 'SEND_EVALUATE_ID_VERIFICATION_IMAGES';
  options: SendEvaluateIDVerificationImagesOptions;
  resolve: (
    evaluateIDResponse: HandlerResponse<IDVerificationEvaluationResponse>
  ) => void;
  reject: () => void;
};

export type SendApplicationReady = {
  type: 'SEND_APPLICATION_READY';
};

export type SendResetApplicationState = {
  type: 'SEND_RESET_APPLICATION_STATE';
};

export type SendAssignSelectedOffer = {
  type: 'SEND_ASSIGN_SELECTED_OFFER';
  selectedOffer: Offer;
};

export type SendAssignIDVerificationFrontImageData = {
  type: 'SEND_ASSIGN_ID_VERIFICATION_FRONT_IMAGE_DATA';
  frontImageData: Blob | null;
};

export type SendAssignIDVerificationBackImageData = {
  type: 'SEND_ASSIGN_ID_VERIFICATION_BACK_IMAGE_DATA';
  backImageData: Blob | null;
};

export type SendAssignPaymentAgreementDocument = {
  type: 'SEND_ASSIGN_PAYMENT_AGREEMENT_DOCUMENT';
  paymentAgreementDocument: PaymentAgreementDocument;
};

export type SendIDVerificationAlloySuccess = {
  type: 'SEND_ID_VERIFICATION_ALLOY_SUCCESS';
};

export type SendIDVerificationAlloyError = {
  type: 'SEND_ID_VERIFICATION_ALLOY_ERROR';
};

export type ApplicationMachineEvents =
  | SendGetApplication
  | SendGetLatestApplication
  | SendCreateApplication
  | SendReCreateApplication
  | SendApplicationCheckout
  | SendApplicationPrepareCheckout
  | SendEvaluateIDVerificationImages
  | SendApplicationReady
  | SendResetApplicationState
  | SendAssignSelectedOffer
  | SendAssignIDVerificationFrontImageData
  | SendAssignIDVerificationBackImageData
  | SendAssignPaymentAgreementDocument
  | SendIDVerificationAlloySuccess
  | SendIDVerificationAlloyError;

/**
 * Services - service type definitions
 */
export type FetchApplication = {
  data: {
    applicationRes: HandlerResponse<Application>;
    resolve: (applicationRes: HandlerResponse<Application>) => void;
  };
};

export type FetchLatestApplication = {
  data: {
    applicationRes: HandlerResponse<Application | null>;
  };
};

export type PrepareInStoreApplication = {
  data: {
    applicationRes: HandlerResponse<Application>;
  };
};

export type PrepareVirtualCardIssuance = {
  data: {
    issuanceResponse: HandlerResponse<VirtualCardIssuanceResponse>;
  };
};

export type CreateApplication = {
  data: {
    applicationRes: HandlerResponse<Application | null>;
  };
};

export type ReCreateApplication = {
  data: {
    applicationRes: HandlerResponse<Application>;
    resolve: (applicationRes: HandlerResponse<Application>) => void;
  };
};

export type CreateApplicationCheckout = {
  data: {
    applicationRes: HandlerResponse<Application>;
    resolve: (applicationRes: HandlerResponse<Application>) => void;
  };
};

export type CreateApplicationPrepareCheckout = {
  data: {
    applicationRes: HandlerResponse<Application>;
    resolve: (applicationRes: HandlerResponse<Application>) => void;
  };
};

export type EvaluateIDVerificationImages = {
  data: {
    evaluateIDResponse: HandlerResponse<IDVerificationEvaluationResponse>;
    resolve: (
      evaluateIDResponse: HandlerResponse<IDVerificationEvaluationResponse>
    ) => void;
  };
};

export type GetErrorStatusCodeFromApplication = {
  data: {
    statusCode: string;
  };
};

export type ApplicationServiceErrorData = {
  error: unknown;
  reject: (error: unknown) => void;
};

export type ApplicationMachineService = {
  fetchApplication: FetchApplication;
  fetchLatestApplication: FetchLatestApplication;
  createApplication: CreateApplication;
  reCreateApplication: ReCreateApplication;
  applicationCheckout: CreateApplicationCheckout;
  applicationPrepareCheckout: CreateApplicationPrepareCheckout;
  getErrorStatusCodeFromApplication: GetErrorStatusCodeFromApplication;
  evaluateIDVerificationImages: EvaluateIDVerificationImages;
  prepareInStoreApplication: PrepareInStoreApplication;
  prepareVirtualCardIssuance: PrepareVirtualCardIssuance;
};

/**
 * Schema definition
 */
export interface ApplicationMachineSchema {
  context: ApplicationMachineContext;
  events: ApplicationMachineEvents;
  services: ApplicationMachineService;
}

export const applicationMachine = createMachine({
  // eslint-disable-next-line max-len
  /** @xstate-layout N4IgpgJg5mDOIC5QEMAOqA2BLAxsgLlgPYB2AxAMoCiAcgCID6ASldQCoMCCACtwDIBJAMKc2AgPI0GFNqKoBtAAwBdRKFRFYWQqTUgAHogAsAJgA0IAJ6IAbCZsA6AIwBOAKwmn9gMweAHN4A7AC+wRZomLgExCQOWCTaWMgYlLSMPPzCohJSLJx0AJpKqkggGlo6JHqGCDZeDt5eHjamLjYuik4W1rVGfg4ufqaBAd6KJm7efqHh6Nh4lQ4AZmD4OAAW8VCcc1GVZBCkYHEkAG5EANbHEfPRpMurG1s7kQsxCPHnb6TFxXrliV0pRqdRMDSa9la7U63UQ3hcLgcNkCThGTlRHTsRhmIBuexiDzWmxI21233IhxIx0+l2uZLusRWROe9MqHzORHJvycJXUmkBVWBtnqjQmkJMbQ6XSsiDcrgaJj84xcgTcRhsbj8ThxePJhKeJJet32lOpHKuDl1DP1xNJrwZ7K+DN+Jl5ZX5lWqwrBouaUKlsIQvjBqNRWr8JkUgRM3h1rIJTINduNMTIYAATumiOmHJgCEtswBbS3x+6J21G-GkR2c50qP6lAGeoW1EUQloS6HSnoTRGKFzeDVOKMmIxGXxuUJhEAkIgQOB6K3NvkVGJehAAWhsgY3MYGCIPh4PIWnS4J8USyX+HrXLfHigabgHLicfUlLnMMt6iL6piMatcExAl8ON7UWdMwGQCAehXAV1wRMEUT8EZ2j8FwjC8PxAxsbwHCMI8OiMRQ-DqE9ZjAgkIKgywTkvDBr1XIFQBqYdNQcJCUMGdDMMDJw3AfQ9AhQ1UnGQsjcVLWIqOgktUCzU5IAAMQAVwwejGxvJiDEQYciPY0TOLQjDFUDMZ+nwwYRnHVV7D40CU3uaSaIieSlNUjBaMIK8NMYwVmJ04dEUjRo7DcMK-D8NxAkDf8jDwg81SlRL7KrKTIJklyiAUiAVLUk5YHwZIMELMASHwBi4JbYcIv05CSK44ysK-OUwUPTxVQjUSTBSvUnNk1ycvchxYEifBuGQGD3V89d0UUB9iKfQI7BVcdX1M9x9wRObFRMexNR660+sy7LxvTLz1Ng5dtIQXSHw4+qjJ45qAnihFvFHNpXDHA7wPS5z0AG07zs8pILqmyr-JuwKHGCpxQvCyLoq-aNEX4ubiLacZUWxU9JIcI6AayyAgdB-LCrUkqyoqq6WOI-p7tQ7iTORmr0c6SUtQHcSz0cv7+qJiASeSYbRvGyam1vSHZvmyKVWWwJVqMbDAgEhLxyIgcMJ+yi+apSBYFyjABAEGhqcl67qqcJFURjIibEUNwbA1QNIx-A8x2jDC6tjXGKN56iHD1iBYAEOgADUMywJZUrNrTabChw0MUJ2nyjMTvBd5FNpfPilUimwSO1stHgrSTY7867Byt-sgntztX1cF2XsUd6UQ8VjfyLxkS62PgCDgfBK3Jcv1y1ROIyVdDHdIqNA34xFXDlFv4WRVEffIhzYhwKjCENMufIhi3FDi+Fk8VCLTBaOHA0CDp2OAqYFa99eJL9red5ZN+hHWMAcAuIhlLlQPjTRAKtnB208OqZCEw6hzyzu0OUmpOj2zmpOX2m8HDb0grvZMqVuAQVQMgCC39f7-0ASPO8n4ejATBEBbw9CdoTHVGg0IQA */
  id: 'application',
  predictableActionArguments: true,
  initial: 'initial',
  context: {
    application: null,
    selectedOffer: null,
    paymentAgreementDocument: null,
    idVerification: {
      frontImageData: null,
      backImageData: null,
      isIDVerified: false,
    },
  },
  tsTypes: {} as import('./applicationMachine.typegen').Typegen0,
  schema: {
    context: {} as ApplicationMachineContext,
    events: {} as ApplicationMachineEvents,
    services: {} as ApplicationMachineService,
  },
  on: {
    SEND_RESET_APPLICATION_STATE: {
      target: 'initial',
      actions: 'resetContext',
    },
  },
  states: {
    initial: {
      on: {
        SEND_GET_LATEST_APPLICATION: {
          target: 'fetchingLatestApplication',
        },
        SEND_APPLICATION_READY: {
          target: 'ready',
        },
      },
    },
    ready: {
      on: {
        SEND_GET_APPLICATION: {
          target: 'fetchingApplication',
        },
        SEND_GET_LATEST_APPLICATION: {
          target: 'fetchingLatestApplication',
        },
        SEND_CREATE_APPLICATION: {
          target: 'creatingApplication',
        },
        SEND_RECREATE_APPLICATION: {
          target: 'reCreatingApplication',
        },
        SEND_APPLICATION_CHECKOUT: {
          target: 'creatingApplicationCheckout',
        },
        SEND_APPLICATION_PREPARE_CHECKOUT: {
          target: 'creatingApplicationPrepareCheckout',
        },
        SEND_ASSIGN_PAYMENT_AGREEMENT_DOCUMENT: {
          actions: 'assignPaymentAgreementDocument',
        },
      },
      initial: 'initial',
      states: {
        initial: {},
        approved: {
          initial: 'initial',
          on: {
            SEND_ASSIGN_SELECTED_OFFER: [
              {
                cond: 'isInstallmentOfferSelected',
                actions: [
                  'assignSelectedOffer',
                  'handleSelectedOfferAnalytics',
                ],
                target: '.selected.installment',
              },
              {
                cond: 'isSplitPayOfferSelected',
                actions: [
                  'assignSelectedOffer',
                  'handleSelectedOfferAnalytics',
                ],
                target: '.selected.splitPay',
              },
              {
                target: '.selected.initial',
              },
            ],
          },
          states: {
            initial: {},
            idVerified: {},
            // In Store returning buyer with application states
            inStore: {
              initial: 'initial',
              states: {
                initial: {},
                prepared: {},
                virtualCard: {},
              },
            },
            // Determined selected offer
            selected: {
              initial: 'initial',
              states: {
                initial: {},
                splitPay: {},
                installment: {},
              },
            },
          },
        },
        needsFullIIN: {
          on: {
            // Override top level event. We want to manually re-create this application
            SEND_GET_LATEST_APPLICATION: {},
          },
        },
        needsIDVerification: {
          on: {
            SEND_ASSIGN_ID_VERIFICATION_FRONT_IMAGE_DATA: {
              actions: 'assignIDVerificationFrontImageData',
              target: '.assigningImage',
            },
            SEND_ASSIGN_ID_VERIFICATION_BACK_IMAGE_DATA: {
              actions: 'assignIDVerificationBackImageData',
              target: '.assigningImage',
            },
            SEND_EVALUATE_ID_VERIFICATION_IMAGES: {
              target: '.evaluatingIDVerificationImages',
            },
          },
          initial: 'initial',
          states: {
            initial: {},
            assigningImage: {},
            formError: {},
            retryError: {},
            evaluatingIDVerificationImages: {
              invoke: {
                src: 'evaluateIDVerificationImages',
                onDone: [
                  {
                    cond: 'isIDVerificationSuccess',
                    actions: [
                      'assignIDVerificationIsIDVerified',
                      'handleIDVerificationDone',
                    ],
                    target: '#application.ready.approved.idVerified',
                  },
                  {
                    cond: 'isIDVerificationFormError',
                    actions: ['handleIDVerificationDone'],
                    target: 'formError',
                  },
                  {
                    cond: 'isIDVerificationNeedsActionWarning',
                    actions: ['handleIDVerificationDone'],
                    target:
                      '#application.errors.idVerificationNeedsActionWarning',
                  },
                  {
                    cond: 'isIDVerificationFailure',
                    actions: ['handleIDVerificationDone'],
                    target: '#application.errors.idVerificationFailure',
                  },
                  {
                    cond: 'isIDVerificationRetryError',
                    actions: ['handleIDVerificationDone'],
                    target: 'retryError',
                  },
                  {
                    cond: 'isIDVerificationErrorMaxAttemptsExceeded',
                    actions: ['handleIDVerificationDone'],
                    target:
                      '#application.errors.idVerificationMaxAttemptsExceeded',
                  },
                  {
                    actions: ['handleIDVerificationDone'],
                    target: '#application.errors.unknown',
                  },
                ],
                onError: [
                  {
                    actions: ['handleApplicationServiceError', 'logError'],
                    target: '#application.errors.unknown',
                  },
                ],
              },
            },
          },
        },
        needsIDVerificationAlloy: {
          on: {
            SEND_ID_VERIFICATION_ALLOY_SUCCESS: {
              target: '#application.ready.approved.idVerified',
            },
            SEND_ID_VERIFICATION_ALLOY_ERROR: {
              target: '#application.errors.unknown',
            },
          },
          initial: 'initial',
          states: {
            initial: {},
          },
        },
        // No route events are associated with this state. Use when you want a wait state
        recoverableError: {},
        checkoutCompleted: {},
        checkoutPrepared: {},
      },
    },
    fetchingApplication: {
      invoke: {
        src: 'fetchApplication',
        onDone: [
          {
            actions: [
              'assignApplication',
              'handleApplicationAnalytics',
              'handleApplicationDone',
            ],
            target: 'ready.approved',
          },
        ],
        onError: [
          {
            actions: ['handleApplicationServiceError', 'logError'],
            target: 'ready.initial',
          },
        ],
      },
    },
    fetchingLatestApplication: {
      invoke: {
        src: 'fetchLatestApplication',
        onDone: [
          {
            cond: 'isUnexpiredInStoreApplication',
            actions: ['assignApplication', 'handleApplicationAnalytics'],
            target: 'preparingInStoreApplication',
          },
          {
            cond: 'isIneligibleAddress',
            target: 'errors.poBoxAddressIneligible',
          },
          {
            cond: 'needsFullIIN',
            target: 'ready.needsFullIIN',
          },
          {
            cond: 'isValidApplicationAndMatchesPreviouslyApprovedOrder',
            actions: ['assignApplication', 'handleApplicationAnalytics'],
            target: 'ready.approved',
          },
          {
            cond: 'isBuyerReadyAndComplete',
            target: 'creatingApplication',
          },
          {
            target: 'ready.initial',
          },
        ],
        onError: [
          {
            actions: ['logError'],
            target: 'ready.initial', // TODO - Should we error out here to errors.unknown. Buyer could get stuck in this state
          },
        ],
      },
    },
    preparingInStoreApplication: {
      invoke: {
        src: 'prepareInStoreApplication',
        onDone: [
          {
            cond: 'isVirtualCardApplication',
            actions: ['assignApplication', 'handleApplicationAnalytics'],
            target: 'preparingVirtualCardApplication',
          },
          {
            target: 'ready.approved.inStore.prepared',
            actions: ['assignApplication', 'handleApplicationAnalytics'],
          },
        ],
        onError: [
          {
            actions: ['logError'],
            target: 'errors.unknown',
          },
        ],
      },
    },
    preparingVirtualCardApplication: {
      invoke: {
        src: 'prepareVirtualCardIssuance',
        onDone: [
          {
            target: 'ready.approved.inStore.virtualCard',
          },
        ],
        onError: [
          {
            actions: ['logError'],
            target: 'errors.unknown',
          },
        ],
      },
    },
    creatingApplication: {
      invoke: {
        src: 'createApplication',
        onDone: [
          {
            cond: 'isApproved',
            actions: [
              'assignApplication',
              'handleApplicationAnalytics',
              'handleOnApproved',
            ],
            target: 'ready.approved',
          },
          {
            cond: 'needsFullIIN',
            actions: ['assignApplication', 'handleApplicationAnalytics'],
            target: 'ready.needsFullIIN',
          },
          {
            cond: 'needsIDVerification',
            actions: [
              'assignApplicationWithAppendedStatusCodes',
              'handleApplicationAnalytics',
            ],
            target: 'ready.needsIDVerification',
          },
          {
            cond: 'needsIDVerificationAlloy',
            actions: [
              'assignApplicationWithAppendedStatusCodes',
              'handleApplicationAnalytics',
            ],
            target: 'ready.needsIDVerificationAlloy',
          },
          {
            target: 'evaluateApplicationError',
          },
        ],
        onError: [
          {
            actions: ['logError'],
            target: 'errors.unknown',
          },
        ],
      },
    },
    reCreatingApplication: {
      invoke: {
        src: 'reCreateApplication',
        onDone: [
          {
            cond: 'isApproved',
            actions: [
              'assignApplication',
              'handleApplicationAnalytics',
              'handleOnApproved',
              'handleApplicationDone',
            ],
            target: 'ready.approved',
          },
          {
            cond: 'needsFullIIN',
            actions: [
              'assignApplication',
              'handleApplicationAnalytics',
              'handleApplicationDone',
            ],
            target: 'ready.needsFullIIN',
          },
          {
            cond: 'needsIDVerification',
            actions: [
              'assignApplicationWithAppendedStatusCodes',
              'handleApplicationAnalytics',
              'handleApplicationDone',
            ],
            target: 'ready.needsIDVerification',
          },
          {
            cond: 'needsIDVerificationAlloy',
            actions: [
              'assignApplicationWithAppendedStatusCodes',
              'handleApplicationAnalytics',
              'handleApplicationDone',
            ],
            target: 'ready.needsIDVerificationAlloy',
          },
          {
            target: 'evaluateApplicationError',
            actions: ['handleApplicationDone'],
          },
        ],
        onError: [
          {
            actions: ['handleApplicationServiceError', 'logError'],
            target: 'errors.unknown',
          },
        ],
      },
    },
    creatingApplicationCheckout: {
      invoke: {
        src: 'applicationCheckout',
        onDone: [
          {
            cond: 'isCheckoutCompleted',
            actions: [
              'assignApplication',
              'handleApplicationAnalytics',
              'handleOnCheckout',
              'handleApplicationDone',
            ],
            target: 'ready.checkoutCompleted',
          },
          {
            cond: 'needsIDVerification',
            actions: [
              'assignApplicationWithAppendedStatusCodes',
              'handleApplicationAnalytics',
              'handleApplicationDone',
            ],
            target: 'ready.needsIDVerification',
          },
          {
            cond: 'needsIDVerificationAlloy',
            actions: [
              'assignApplicationWithAppendedStatusCodes',
              'handleApplicationAnalytics',
              'handleApplicationDone',
            ],
            target: 'ready.needsIDVerificationAlloy',
          },
          {
            cond: 'isDownPaymentAuthDeclinedError',
            actions: ['handleApplicationDone'],
            target: 'ready.recoverableError',
          },
          {
            target: 'evaluateApplicationError',
            actions: ['handleApplicationDone'],
          },
        ],
        onError: [
          {
            actions: ['handleApplicationServiceError', 'logError'],
            target: 'errors.unknown',
          },
        ],
      },
    },
    creatingApplicationPrepareCheckout: {
      invoke: {
        src: 'applicationPrepareCheckout',
        onDone: [
          {
            cond: 'isCheckoutPrepared',
            actions: [
              'assignApplication',
              'handleApplicationAnalytics',
              'handleApplicationDone',
            ],
            target: 'ready.checkoutPrepared',
          },
          {
            cond: 'needsIDVerification',
            actions: [
              'assignApplicationWithAppendedStatusCodes',
              'handleApplicationAnalytics',
              'handleApplicationDone',
            ],
            target: 'ready.needsIDVerification',
          },
          {
            cond: 'needsIDVerificationAlloy',
            actions: [
              'assignApplicationWithAppendedStatusCodes',
              'handleApplicationAnalytics',
            ],
            target: 'ready.needsIDVerificationAlloy',
          },
          {
            cond: 'isErrorResult',
            actions: ['handleApplicationDone'],
            target: 'ready.recoverableError',
          },
          {
            actions: ['handleApplicationDone'],
            target: 'evaluateApplicationError',
          },
        ],
        onError: [
          {
            actions: ['handleApplicationServiceError', 'logError'],
            target: 'errors.unknown',
          },
        ],
      },
    },
    evaluateApplicationError: {
      invoke: {
        src: 'getErrorStatusCodeFromApplication',
        onDone: [
          {
            cond: 'isPoBoxAddressIneligible',
            target: 'errors.poBoxAddressIneligible',
          },
          {
            cond: 'isBuyerHashFailed',
            target: 'errors.buyerHashFailed',
          },
          {
            cond: 'isCreditFreeze',
            target: 'errors.creditFreeze',
          },
          {
            cond: 'isKycDenial',
            target: 'errors.kycDenial',
          },
          {
            cond: 'isPreviousDenialIneligible',
            target: 'errors.previousDenialIneligible',
          },
          {
            cond: 'isMaxCardIneligible',
            target: 'errors.maxCardIneligible',
          },
          {
            cond: 'isOutstandingLoansIneligible',
            target: 'errors.outstandingLoansIneligible',
          },
          {
            cond: 'isAgeIneligible',
            target: 'errors.ageIneligible',
          },
          {
            cond: 'isAgeAlabamaMilitary',
            target: 'errors.ageAlabamaMilitary',
          },
          {
            cond: 'isBuyerStatusIneligible',
            target: 'errors.buyerStatusIneligible',
          },
          {
            cond: 'isBuyerSkippedInstallments',
            target: 'errors.buyerSkippedInstallments',
          },
          {
            cond: 'isLocationIneligible',
            target: 'errors.locationIneligible',
          },
          {
            cond: 'isNeedsActionWarning',
            target: 'errors.needsActionWarning',
          },
          {
            cond: 'isFraudDenial',
            target: 'errors.fraudDenial',
          },
          {
            cond: 'isCreditDenial',
            target: 'errors.creditDenial',
          },
          {
            cond: 'isSanctionsOFACDenial',
            target: 'errors.sanctionsOFACDenial',
          },
          {
            cond: 'isSanctionsDenial',
            target: 'errors.sanctionsDenial',
          },
          {
            cond: 'isFraudAlertDenial',
            target: 'errors.fraudAlertDenial',
          },
          {
            cond: 'isCapacityRecheck',
            target: 'errors.capacityRecheck',
          },
          {
            target: 'errors.unknown',
          },
        ],
        onError: [
          {
            target: 'errors.unknown',
          },
        ],
      },
    },
    errors: {
      initial: 'unknown',
      states: {
        poBoxAddressIneligible: {
          on: {
            SEND_CREATE_APPLICATION: {
              target: '#application.creatingApplication',
            },
          },
        },
        buyerHashFailed: {
          on: {
            SEND_RECREATE_APPLICATION: {
              target: '#application.reCreatingApplication',
            },
          },
        },
        creditFreeze: {},
        kycDenial: {},
        previousDenialIneligible: {},
        maxCardIneligible: {},
        outstandingLoansIneligible: {},
        ageIneligible: {},
        ageAlabamaMilitary: {},
        buyerStatusIneligible: {},
        buyerSkippedInstallments: {},
        locationIneligible: {},
        needsActionWarning: {},
        fraudDenial: {},
        creditDenial: {},
        sanctionsOFACDenial: {},
        sanctionsDenial: {},
        fraudAlertDenial: {},
        idVerificationNeedsActionWarning: {},
        idVerificationFailure: {},
        idVerificationMaxAttemptsExceeded: {},
        capacityRecheck: {
          on: {
            SEND_RECREATE_APPLICATION: {
              target: '#application.reCreatingApplication',
            },
          },
        },
        unknown: {},
      },
    },
  },
});
