import _defineProperty from "/opt/bread/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

var _REASONS;

export var DOMAINS = {
  APPLICATION: 'APPLICATION',
  DECISION: 'DECISION',
  ELIGIBILITY: 'ELIGIBILITY',
  FRAUD: 'FRAUD',
  ID_VERIFICATION: 'ID_VERIFICATION',
  KYC: 'KYC',
  SANCTIONS: 'SANCTIONS',
  AUTH: 'Auth',
  BUYER: 'Buyer',
  PAYMENTS: 'PAYMENTS',
  DOWN_PAYMENT_AUTH: 'DOWN_PAYMENT_AUTH',
  VIRTUAL_CARD: 'VIRTUAL_CARD'
};
var APPLICATION_REASONS = {
  BUYER_HASH_CHECK_FAILED: 'BUYER_HASH_CHECK_FAILED'
};
var AUTH_REASONS = {
  INVALID_TOKEN: 'Invalid_Token',
  REQUEST_VALIDATION: 'Request_Validation',
  TOO_LOGIN_MANY_ATTEMPTS: 'Too_Many_Login_Attempts',
  UNAUTHENTICATED_RETRY_WITH_PII: 'Unauthenticated_Retry_With_PII',
  UNAUTHENTICATED: 'Unauthenticated'
};
var BUYER_REASONS = {
  IDENTITY_MISMATCH: 'Identity_Mismatch',
  BUYER_VALIDATION: 'Buyer_Validation',
  EMAIL_ALREADY_EXISTS: 'Email_Already_Exists',
  PHONE_ALREADY_EXISTS: 'Phone_Already_Exists',
  BUYER_CONFLICT: 'Buyer_Conflict'
};
var DECISION_REASONS = {
  APPROVED_FULL: 'APPROVED_FULL',
  APPROVED_PARTIAL: 'APPROVED_PARTIAL',
  APPROVED_CONTINGENT: 'APPROVED_CONTINGENT',
  CREDIT_FREEZE: 'CREDIT_FREEZE',
  DENIED_CREDIT: 'DENIED_CREDIT',
  DENIED_FRAUD_ALERT: 'DENIED_FRAUD_ALERT',
  INSUFFICIENT_DATA: 'INSUFFICIENT_DATA',
  MAX_CARD_ATTEMPT: 'MAX_CARD_ATTEMPT',
  NEEDS_FULL_IIN: 'NEEDS_FULL_IIN',
  OUTSTANDING_LOANS: 'OUTSTANDING_LOANS',
  PREVIOUS_DENIAL: 'PREVIOUS_DENIAL'
};
var DOWN_PAYMENT_AUTH_REASONS = {
  DECLINED_RETRY_PAYMENT_METHOD: 'DECLINED_RETRY_PAYMENT_METHOD'
};
var ELIGIBILITY_REASONS = {
  INELIGIBLE_LOCATION: 'INELIGIBLE_LOCATION',
  INELIGIBLE_AGE: 'INELIGIBLE_AGE',
  INELIGIBLE_BUYER_STATUS: 'INELIGIBLE_BUYER_STATUS'
};
var FRAUD_REASONS = {
  FRAUD_FAIL: 'FRAUD_FAIL',
  EXTENDED_FRAUD_ALERT: 'EXTENDED_FRAUD_ALERT',
  HIGH_RISK: 'HIGH_RISK',
  LOW_RISK: 'LOW_RISK',
  ID_DENIAL: 'ID_DENIAL',
  MAX_ID_VERIFICATION_ATTEMPTS_EXCEEDED: 'MAX_ID_VERIFICATION_ATTEMPTS_EXCEEDED'
};
var ID_VERIFICATION_REASONS = {
  BARCODE_NOT_DETECTED: 'barcode_not_detected',
  TOO_BLURRY: 'too_blurry',
  SIDE_SUBMITTED_TWICE: 'side_submitted_twice',
  EXPIRED: 'expired',
  TOO_MUCH_GLARE: 'too_much_glare',
  PORTRAIT_NOT_CLEAR: 'portrait_not_clear'
};
var KYC_REASONS = {
  FAILED: 'FAILED',
  ID_DENIAL: 'ID_DENIAL',
  UNKNOWN: 'UNKNOWN/NOT_AVAILABLE'
};
var PAYMENTS_REASONS = {
  INVALID_PAYMENT_METHOD_ERROR: 'INVALID_PAYMENT_METHOD_ERROR',
  WRITE_CARD_ACCOUNT_ERROR: 'WRITE_CARD_ACCOUNT_ERROR',
  GET_CARD_CONNECTION_ERROR: 'GET_CARD_CONNECTION_ERROR',
  TRANSACTION_ERROR: 'TRANSACTION_ERROR',
  GET_PAYMENT_METHOD_ERROR: 'GET_PAYMENT_METHOD_ERROR',
  AUTHORIZE_PAYMENT_ERROR: 'AUTHORIZE_PAYMENT_ERROR'
};
var SANCTIONS_REASONS = {
  PASSED: 'PASSED',
  INCOMPLETE: 'INCOMPLETE'
};
var VIRTUAL_CARD_REASONS = {
  FAILED: 'FAILED'
};
export var REASONS = (_REASONS = {}, _defineProperty(_REASONS, DOMAINS.APPLICATION, APPLICATION_REASONS), _defineProperty(_REASONS, DOMAINS.AUTH, AUTH_REASONS), _defineProperty(_REASONS, DOMAINS.BUYER, BUYER_REASONS), _defineProperty(_REASONS, DOMAINS.DECISION, DECISION_REASONS), _defineProperty(_REASONS, DOMAINS.DOWN_PAYMENT_AUTH, DOWN_PAYMENT_AUTH_REASONS), _defineProperty(_REASONS, DOMAINS.ELIGIBILITY, ELIGIBILITY_REASONS), _defineProperty(_REASONS, DOMAINS.FRAUD, FRAUD_REASONS), _defineProperty(_REASONS, DOMAINS.ID_VERIFICATION, ID_VERIFICATION_REASONS), _defineProperty(_REASONS, DOMAINS.KYC, KYC_REASONS), _defineProperty(_REASONS, DOMAINS.PAYMENTS, PAYMENTS_REASONS), _defineProperty(_REASONS, DOMAINS.SANCTIONS, SANCTIONS_REASONS), _defineProperty(_REASONS, DOMAINS.VIRTUAL_CARD, VIRTUAL_CARD_REASONS), _REASONS);
export var ID_VERIFICATION_STATUS_CODES = {
  DocumentEvaluationHighRisk: '040102',
  DocumentEvaluationExtendedFraudAlert: '040104',
  KYCFailIDDenial: '050404'
};
export var DECISION_DENIED_STATUS_CODES = ['DecisionDeniedDeniedCredit', 'DecisionDeniedDeniedNoCreditFile', 'DecisionDeniedDeniedNoCreditScore', 'DecisionDeniedDeniedThinFile', 'DecisionDeniedDeniedRepossessions', 'DecisionDeniedDeniedForeclosure', 'DecisionDeniedDeniedDerogatoryRecord', 'DecisionDeniedDeniedTradePastDue', 'DecisionDeniedDeniedCollections', 'DecisionDeniedDeniedRevolvingCreditUtilization', 'DecisionDeniedDeniedInsufficientCapacity', 'DecisionDeniedDeniedAuthorizedTradelines', 'DecisionDeniedDeniedFraudAlert', 'DecisionIneligibleIneligibleSkippedInstallments', 'DecisionDeniedDeniedLowFICO', 'DecisionDeniedDeniedCrust', 'DecisionDeniedLowCreditLimit', 'DecisionDeniedLowFicoLowCreditLimit', 'DecisionDeniedDeniedBreadChargeOff', 'DecisionDeniedDeniedPastLoanOverdue', 'DecisionDeniedDeniedLoanCurrentlyOverdue', 'DecisionDeniedDeniedChargeOff', 'DecisionDeniedDeniedAccountPastDue', 'DecisionDeniedDeniedInquiries', 'DecisionDeniedDenied', 'DecisionDeniedDeniedCreditAbuse', 'DecisionDeniedDeniedCreditopticsDeceased', 'DecisionDeniedDeniedCreditopticsDecline', 'DecisionDeniedDeniedBureauDeceased', 'DecisionDeniedDeniedPreciseidDeceased', 'DecisionDeniedDeniedPreciseidDecline', 'DecisionDeniedDeniedEquifaxDeceased', 'DecisionDeniedDeniedBureauData', 'DecisionDeniedDeniedLowBureau', 'FraudDeniedPreciseIDDenied', 'KYCIncompleteCreditFreeze'];