import React, {
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Accordion, Section } from '../Accordion';
import { TextLink } from '../TextLink';
import { useMerchantPaymentProducts, useVirtualCard } from '../XPropsContext';
import SlideOut from '../SlideOut';
import { useAppConfig } from '../AppConfigContext';
import { LearnMore } from './FAQs';

type Props = {
  onBack: () => void;
  className?: string;
};

type FAQProps = Props & {
  isShowing: boolean;
  setIsShowing: Dispatch<SetStateAction<boolean>>;
};

const faqFormattingValues = {
  p: (chunks: ReactNode) => <p className="mt-4 first:mt-0 mb-1">{chunks}</p>,
};

export const InStoreFAQs: FunctionComponent<FAQProps> = ({
  isShowing,
  setIsShowing,
  onBack,
}) => {
  const { hasInstallments, hasSplitPay } = useMerchantPaymentProducts();
  const { isVirtualCard } = useVirtualCard();

  return (
    <SlideOut
      isOpen={isShowing}
      onClose={() => setIsShowing(false)}
      afterLeave={() => onBack()}
    >
      {isVirtualCard ? <VirtualCardGeneralFAQs /> : <InStoreGeneralFAQs />}
      {isVirtualCard && <InStoreOneTimeUseCardFAQs />}
      {hasInstallments && <InStoreInstallmentsFAQs />}
      {hasSplitPay && <InStoreSplitPayFAQs />}

      <LearnMore
        showSplitPay={hasSplitPay}
        showInstallments={hasInstallments}
      />
    </SlideOut>
  );
};

const InStoreGeneralFAQs: FunctionComponent = () => {
  const intl = useIntl();
  const { supportUrl, helpPhone } = useAppConfig();
  return (
    <div>
      <h2 className="text-lg font-extrabold mb-4">
        <FormattedMessage
          defaultMessage="General Questions"
          description="FAQ Screen General heading"
        />
      </h2>
      <Accordion>
        <Section
          title={intl.formatMessage({
            defaultMessage: 'What is Bread Pay™?',
            description: 'FAQ Screen General question 1',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Bread Pay™ is a pay-over-time technology solution that helps you make purchases with easy-to-understand equal payments. Bread Pay™ products include the Bread Pay™ Installment plan, with terms from 3 to 48 months, and the Bread Pay™ SplitPay plan, with four payments made every two weeks, each payment being 25% of the original purchase amount.</p>"
            description="FAQ Screen General answer 1"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'I want to find out if I’m eligible for Bread Pay™. Will this impact my credit score?',
            description: 'FAQ Screen General question 2',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Checking your eligibility does not affect your credit score since only a soft inquiry is completed. If you choose a Bread Pay™ payment plan after your eligibility is confirmed, we will not perform a hard inquiry with any of the credit reporting agencies.</p>

          <p>Bread Pay™ may report repayment information about your account to the consumer reporting agencies, which may impact your credit score.</p>"
            description="FAQ Screen General answer 2"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'Does prequalifying for Bread Pay™ impact my credit score?',
            description: 'FAQ Screen General question 3',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Checking your eligibility does not affect your credit score since only a soft inquiry is completed. If you choose a Bread Pay™ payment plan after your eligibility is confirmed, we will not perform a hard inquiry with any of the credit reporting agencies.</p>

          <p>Bread Pay™ may report repayment information about your account to the consumer reporting agencies, which may impact your credit score.</p>"
            description="FAQ Screen General answer 3"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage: 'How is my credit eligibility determined?',
            description: 'FAQ Screen General question 4',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>We work with our bank affiliate, Comenity Capital Bank, which determines eligibility by reviewing factors such as your credit score and repayment history.</p>"
            description="FAQ Screen General answer 4"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'How do I make an in-store purchase with Pay Later?',
            description: 'FAQ Screen General question 6',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>With Pay Later, you’ll receive a confirmation code to use at checkout. This confirmation code is valid only at the retail partner's physical store and will expire within 12 hours. You'll need to present your confirmation code to the retail store associate with a valid photo ID to check out with your selected payment plan.</p>
                "
            description="FAQ Screen General answer 6"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage: 'How much can I spend in-store with Pay Later?',
            description: 'FAQ Screen General question 5',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>The amount you can spend in-store with Pay Later is based on your eligibility and the payment plan you select. If you qualify for a payment plan, you'll be presented with the amount you can spend up to with each payment product. Your payment amount will be based on your final purchase amount and your loan will not be completed until you check out with your confirmation code at the register in the retailer's physical store location.</p>"
            description="FAQ Screen General answer 5"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'What should I do if I am not receiving my mobile verification code?',
            description: 'FAQ Screen General question 7',
          })}
        >
          <FormattedMessage
            defaultMessage='<p>Please wait a few minutes before requesting a new mobile verification code to avoid receiving multiple texts with different codes all at once.
          The phone number you provide must be a mobile number that is able to receive text messages.</p>

          <p>You’ll have five attempts to validate your mobile verification code before being blocked for 15 minutes.
             If you are still not receiving a mobile verification code, we recommend you request a new verification code by selecting "Resend code".</p>

          <p>We’re unable to send codes to prepaid or Cricket phones. If you have this type of phone or need additional assistance receiving a code, please contact our Member Support team <link>here</link>.</p>'
            description="FAQ Screen General answer 7"
            values={{
              ...faqFormattingValues,
              link: (chunks: ReactNode) => (
                <TextLink
                  href={supportUrl}
                  target="_blank"
                  testID="customer-support"
                >
                  {chunks}
                </TextLink>
              ),
            }}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage: 'How do I contact customer support?',
            description: 'FAQ Screen General question 8',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>You can reach our Customer Support team at <tel>{phoneNumber}</tel> or <mail>support@breadpayments.com</mail>. For more information about our hours of operation, visit our <link>Contact Us</link> page.</p>"
            description="FAQ Screen General answer 8"
            values={{
              ...faqFormattingValues,
              phoneNumber: helpPhone,
              tel: (chunks: ReactNode) => (
                <TextLink
                  href={`tel:${helpPhone}`}
                  testID="customer-support-phone"
                >
                  {chunks}
                </TextLink>
              ),
              mail: (chunks: ReactNode) => (
                <TextLink
                  href="mailto:support@breadpayments.com"
                  testID="customer-support-email"
                >
                  {chunks}
                </TextLink>
              ),
              link: (chunks: ReactNode) => (
                <TextLink
                  href={supportUrl}
                  target="_blank"
                  testID="customer-support-link"
                >
                  {chunks}
                </TextLink>
              ),
            }}
          />
        </Section>
      </Accordion>
    </div>
  );
};

const VirtualCardGeneralFAQs: FunctionComponent = () => {
  const intl = useIntl();
  const { supportUrl } = useAppConfig();
  return (
    <div>
      <h2 className="text-lg font-extrabold mb-4">
        <FormattedMessage
          defaultMessage="General Questions"
          description="FAQ Screen General heading"
        />
      </h2>
      <Accordion>
        <Section
          title={intl.formatMessage({
            defaultMessage: 'What is Bread Pay™?',
            description: 'FAQ Screen General question 1',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Bread Pay™ is a pay-over-time technology solution that helps you make purchases with easy-to-understand equal payments. Bread Pay™ products include the Bread Pay™ Installment plan, with terms from 3 to 48 months, and the Bread Pay™ SplitPay plan, with four payments made every two weeks, each payment being 25% of the original purchase amount.</p>"
            description="FAQ Screen General answer 1"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'Does prequalifying for Bread Pay™ impact my credit score?',
            description: 'FAQ Screen General question 3',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Checking your eligibility does not affect your credit score since only a soft inquiry is completed. If you choose a Bread Pay™ payment plan after your eligibility is confirmed, we will not perform a hard inquiry with any of the credit reporting agencies.</p>

          <p>Bread Pay™ may report repayment information about your account to the consumer reporting agencies, which may impact your credit score.</p>"
            description="FAQ Screen General answer 3"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'I want to find out if I’m eligible for Bread Pay™. Will this impact my credit score?',
            description: 'FAQ Screen General question 2',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Checking your eligibility does not affect your credit score since only a soft inquiry is completed. If you choose a Bread Pay™ payment plan after your eligibility is confirmed, we will not perform a hard inquiry with any of the credit reporting agencies.</p>

          <p>Bread Pay™ may report repayment information about your account to the consumer reporting agencies, which may impact your credit score.</p>"
            description="FAQ Screen General answer 2"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'What should I do if I am not receiving my mobile verification code?',
            description: 'FAQ Screen General question 7',
          })}
        >
          <FormattedMessage
            defaultMessage='<p>Please wait a few minutes before requesting a new mobile verification code to avoid receiving multiple texts with different codes all at once.
          The phone number you provide must be a mobile number that is able to receive text messages.</p>

          <p>You’ll have five attempts to validate your mobile verification code before being blocked for 15 minutes.
             If you are still not receiving a mobile verification code, we recommend you request a new verification code by selecting "Resend code".</p>

          <p>We’re unable to send codes to prepaid or Cricket phones. If you have this type of phone or need additional assistance receiving a code, please contact our Member Support team <link>here</link>.</p>'
            description="FAQ Screen General answer 7"
            values={{
              ...faqFormattingValues,
              link: (chunks: ReactNode) => (
                <TextLink
                  href={supportUrl}
                  target="_blank"
                  testID="customer-support"
                >
                  {chunks}
                </TextLink>
              ),
            }}
          />
        </Section>
      </Accordion>
    </div>
  );
};

const InStoreInstallmentsFAQs: FunctionComponent = () => {
  const intl = useIntl();
  const { isVirtualCard } = useVirtualCard();

  return (
    <div>
      <h2 className="text-lg font-extrabold mb-4">
        <FormattedMessage
          defaultMessage="Installments FAQs"
          description="FAQ Screen Installments heading"
        />
      </h2>
      <Accordion>
        <Section
          title={intl.formatMessage({
            defaultMessage: 'What is a Bread Pay™ Installment plan?',
            description: 'FAQ Screen Installments question 1',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>A Bread Pay™ Installment plan is a pay-over-time technology solution that helps you make purchases with easy-to-understand, equal payments. With our installment plans, you pay us back monthly over 3 to 48 months with APRs ranging from 0% to 29.99%. Keep in mind, the available loan terms may vary based on the retailer.</p>"
            description="FAQ Screen Installments answer 1"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'Am I eligible to apply for a Bread Pay™ Installment plan?',
            description: 'FAQ Screen Installments question 2',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>To apply for a Bread Pay™ Installment plan, you must meet your state’s age of majority – the age your state of residence defines as required to legally enter into a contract. You’ll also need a valid phone number, email address and mobile device that allows for a digital wallet.</p>

            <p>Bread Financial™ must be able to verify your identity based on the information you provide in the application. Checking your eligibility does not affect your credit score since only a soft inquiry is completed.</p>"
            description="FAQ Screen Installments answer 2"
            values={faqFormattingValues}
          />
        </Section>

        {isVirtualCard && (
          <Section
            title={intl.formatMessage({
              defaultMessage: 'How is my credit eligibility determined?',
              description: 'FAQ Screen General question 4',
            })}
          >
            <FormattedMessage
              defaultMessage="<p>We work with our bank affiliate, Comenity Capital Bank, which determines eligibility by reviewing factors such as your credit score and repayment history.</p>"
              description="FAQ Screen General answer 4"
              values={faqFormattingValues}
            />
          </Section>
        )}

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'How is the APR on my Bread Pay™ Installment plan determined?',
            description: 'FAQ Screen Installments question 3',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Our bank affiliate, Comenity Capital Bank, determines the APR based on a variety of factors. These factors typically include your credit history and credit score, amount financed and length of the term, though other factors may be considered.</p>"
            description="FAQ Screen Installments answer 3"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'What installment plan payment methods do you accept?',
            description: 'FAQ Screen Installments question 4',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>You can make payments on Bread Pay™ Installment plans using ACH funds transfer or a personal debit card. You cannot make payments on Bread Pay™ Installment plans with a credit card, business debit card, bill pay or check. Additionally, Bread Pay™ does not accept Flexible Spending Account (FSA) or Health Savings Account (HSA) payment cards because we are not designated as a health services retailer. Payments must be received by 5:00 p.m. Eastern Time (ET) on your due date.</p>"
            description="FAQ Screen Installments answer 4"
            values={faqFormattingValues}
          />
        </Section>
      </Accordion>
    </div>
  );
};

const InStoreSplitPayFAQs: FunctionComponent = () => {
  const intl = useIntl();
  const { isVirtualCard } = useVirtualCard();

  return (
    <div>
      <h2 className="text-lg font-extrabold mb-4">
        <FormattedMessage
          defaultMessage="SplitPay FAQs"
          description="FAQ Screen SplitPay heading"
        />
      </h2>
      <Accordion>
        <Section
          title={intl.formatMessage({
            defaultMessage: 'What is Bread Pay™ SplitPay?',
            description: 'FAQ Screen SplitPay question 1',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Bread Pay™ SplitPay is a payment option that enables you to pay for your purchase with four equal and interest-free payments using a personal credit card or debit card.</p>"
            description="FAQ Screen SplitPay answer 1"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'Am I eligible to apply for a Bread Pay™ SplitPay plan?',
            description: 'FAQ Screen SplitPay question 2',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>To apply for a Bread Pay™ SplitPay plan, you must meet your state’s age of majority – the age your state of residence defines as required to legally enter into a contract. You’ll also need a valid phone number, email address and mobile device that allows for a digital wallet.</p>

            <p>Bread Financial™ must be able to verify your identity based on the information you provide in the application. Checking your eligibility does not affect your credit score since only a soft inquiry is completed.</p>"
            description="FAQ Screen SplitPay answer 2"
            values={faqFormattingValues}
          />
        </Section>

        {isVirtualCard && (
          <Section
            title={intl.formatMessage({
              defaultMessage:
                'I want to find out if I’m eligible for Bread Pay™ SplitPay. Will this impact my credit score?',
              description: 'FAQ Screen General question 2',
            })}
          >
            <FormattedMessage
              defaultMessage="<p>Checking your eligibility does not affect your credit score since only a soft inquiry is completed. If you choose a Bread Pay™ payment plan after your eligibility is confirmed, we will not perform a hard inquiry with any of the credit reporting agencies.</p>

          <p>Bread Pay™ may report repayment information about your account to the consumer reporting agencies, which may impact your credit score.</p>"
              description="FAQ Screen General answer 2"
              values={faqFormattingValues}
            />
          </Section>
        )}

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'What Bread Pay™ SplitPay payment methods do you accept?',
            description: 'FAQ Screen SplitPay question 3',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>You can make payments on Bread Pay™ SplitPay using a personal debit card or credit card. You cannot make Bread Pay™ SplitPay payments with a business debit card, ACH funds transfer or check. Additionally, Bread Pay™ does not accept Flexible Spending Account (FSA) or Health Savings Account (HSA) payment cards because we are not designated as a health services retailer. Please note, payments must be received by 5:00 p.m. Eastern Time (ET) on your due date.</p>"
            description="FAQ Screen SplitPay answer 3"
            values={faqFormattingValues}
          />
        </Section>
      </Accordion>
    </div>
  );
};

const InStoreOneTimeUseCardFAQs: FunctionComponent = () => {
  const intl = useIntl();
  const { helpPhone, supportUrl } = useAppConfig();

  return (
    <div>
      <h2 className="text-lg font-extrabold mb-4">
        <FormattedMessage
          defaultMessage="In-Store One-Time Use Card FAQs"
          description="FAQ Screen In-Store One-Time UseCard heading"
        />
      </h2>
      <Accordion>
        <Section
          title={intl.formatMessage({
            defaultMessage: 'What is a BreadPay™ One-Time Use Card?',
            description: 'FAQ Screen In-Store One-Time UseCard question 1',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>The Bread Pay™ One-Time Use Card is a virtual card that can be used in-store on a purchase. It’s valid for seven days. Once you’re approved for the Bread Pay™ Installment plan or Bread Pay™ SplitPay plan, simply add the card to your digital wallet and tap to pay with your phone.</p>"
            description="FAQ Screen In-Store One-Time UseCard answer 1"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'How do I apply for the Bread Pay™ One-Time Use Card?',
            description: 'FAQ Screen In-Store One-Time UseCard question 2',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Scan the QR code in-store to learn more and start the application. Once you’re approved for the Bread Pay™ Installment plan or Bread Pay™ SplitPay plan, simply add the card to your digital wallet and tap to pay with your phone.</p>"
            description="FAQ Screen In-Store One-Time UseCard answer 2"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'Am I eligible to apply for a Bread Pay™ One-Time Use Card?',
            description: 'FAQ Screen In-Store One-Time UseCard question 6',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>To apply for a Bread Pay™ One-Time Use Card, you must you meet your state’s age of majority – the age your state of residence defines as required to legally enter into a contract. You’ll also need a valid phone number, email address and mobile device that allows for a digital wallet.</p>

            <p>Bread Financial™ must be able to verify your identity based on the information you provide in the application. Checking your eligibility does not affect your credit score since only a soft inquiry is completed.</p>"
            description="FAQ Screen In-Store One-Time UseCard answer 6"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage: 'Where can I use my Bread Pay™ One-Time Use Card?',
            description: 'FAQ Screen In-Store One-Time UseCard question 3',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>You can use the card in-store at a participating merchant location. You must have previously applied and been approved with this merchant for a Bread Pay™ Installment plan or Bread Pay™ SplitPay plan.</p>"
            description="FAQ Screen In-Store One-Time UseCard answer 3"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage: 'How do I make an in-store purchase with the card?',
            description: 'FAQ Screen In-Store One-Time UseCard question 4',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Once you’ve selected your eligible Bread Pay™ Installment plan or Bread Pay™ SplitPay plan and you’re approved, add your card to your digital wallet then just tap to pay at the checkout.</p>"
            description="FAQ Screen In-Store One-Time UseCard answer 4"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage:
              'How much can I spend in-store with my Bread Pay™ One-Time Use Card?',
            description: 'FAQ Screen In-Store One-Time UseCard question 5',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>The amount you can spend in-store with your Bread Pay™ One-Time Use Card is based on your eligibility and the payment plan you select. If you qualify for a payment plan, you'll be presented with the amount you can spend up to with each payment product. Your payment amount will be based on your final purchase amount and your loan will not be completed until you use the card in your digital wallet at the register to complete your purchase.</p>"
            description="FAQ Screen In-Store One-Time UseCard answer 5"
            values={faqFormattingValues}
          />
        </Section>

        <Section
          title={intl.formatMessage({
            defaultMessage: 'How do I add my card to my digital wallet?',
            description: 'FAQ Screen In-Store One-Time UseCard question 7',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Once your application has been approved, you’ll be prompted to add the Bread Pay™ One-Time Use Card to your digital wallet. On the “Add Card to Wallet” screen, select the wallet for your device, either Apple Wallet or Google Pay. After successfully adding to your wallet, your card is all set!</p>"
            description="FAQ Screen In-Store One-Time UseCard answer 7"
            values={faqFormattingValues}
          />
        </Section>
        <Section
          title={intl.formatMessage({
            defaultMessage: 'Is my card safe and secure in my digital wallet?',
            description: 'FAQ Screen In-Store One-Time UseCard question 8',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Yes! Digital wallets use multi-layered security to keep your card information safe. Your personal information is never shared with the merchant or stored on the mobile device. Checkout is fast and simple – with added peace of mind.</p>"
            description="FAQ Screen In-Store One-Time UseCard answer 8"
            values={faqFormattingValues}
          />
        </Section>
        <Section
          title={intl.formatMessage({
            defaultMessage:
              ' Help! I can’t check out with my Bread Pay™ One-Time Use Card.',
            description: 'FAQ Screen In-Store One-Time UseCard question 9',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Having trouble locating your card? Please check your digital wallet and look for the Bread Pay™ One-Time Use Card. If you do not see the card there, you can access it on the confirmation page of your Bread Pay™ application.</p>

            <p>Having trouble adding your card? For help setting up your digital wallet, please visit <apple>Apple</apple> or <google>Google</google>. When you’re adding the one-time use card to your mobile device, a security verification will be required. If your mobile device cannot be verified, you may not be able to complete your transaction. For issues with using or accessing your card, please visit our Member Portal. You may also <link>contact us</link>.</p>"
            description="FAQ Screen In-Store One-Time UseCard answer 9"
            values={{
              ...faqFormattingValues,
              apple: (chunks: ReactNode) => (
                <TextLink
                  href="https://support.apple.com/apple-pay"
                  target="_blank"
                  testID="apple-support-link"
                >
                  {chunks}
                </TextLink>
              ),
              google: (chunks: ReactNode) => (
                <TextLink
                  href="https://support.google.com/googlepay/?hl=en#topic=11450859"
                  target="_blank"
                  testID="google-support-link"
                >
                  {chunks}
                </TextLink>
              ),
              link: (chunks: ReactNode) => (
                <TextLink
                  href={supportUrl}
                  target="_blank"
                  testID="customer-support-link"
                >
                  {chunks}
                </TextLink>
              ),
            }}
          />
        </Section>
        <Section
          title={intl.formatMessage({
            defaultMessage:
              'Can I make returns with my Bread Pay™ One-Time Use Card? ',
            description: 'FAQ Screen In-Store One-Time UseCard question 10',
          })}
        >
          <FormattedMessage
            defaultMessage="<p>Yes, you can make a return, subject to the merchant return policy. We suggest you keep a receipt for any returns.</p>

            <p>If you don’t have a receipt and the merchant is unable to find your original payment method, you can log in to the Bread Financial™ Member Portal to find your Bread Pay™ One-Time Use Card number or contact Customer Support at <tel>{phoneNumber}</tel>.</p>
            "
            description="FAQ Screen In-Store One-Time UseCard answer 10"
            values={{
              ...faqFormattingValues,
              phoneNumber: helpPhone,
              tel: (chunks: ReactNode) => (
                <TextLink
                  href={`tel:${helpPhone}`}
                  testID="customer-support-phone"
                >
                  {chunks}
                </TextLink>
              ),
            }}
          />
        </Section>
      </Accordion>
    </div>
  );
};
