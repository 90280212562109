import { isLeft, isRight, left, right, map, mapLeft } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { formatValidationErrors } from 'io-ts-reporters';
import {
  APIError,
  isAPIError,
  PaymentsAPIError,
} from '@ads-bread/shared/bread/codecs';
import {
  useAppID,
  useMerchantID,
  useProgramID,
} from '../../components/XPropsContext';
import { useAuthTokenStore } from './useAuthTokenStore';

export type Method = 'POST' | 'PUT' | 'GET';
export const RE_URL_PROTOCOL = /^https?\/\/:/;
export const UNAUTH_STATUSES = [401, 403];

export type APISuccessResponse<T> = {
  error: null;
  response: SuccessResponse<T>;
};
export type APIErrorResponse = { error: APIError; response: null };

// NOTE: originally, we had wanted to return a tuple [APIErrorResponse, APISuccessResponse<T>]
//    e.g. const [ error, response ] = await apiFetch(...)
// but the type checker could not properly narrow the types after destructuring the array
// at call sites. See: https://github.com/microsoft/TypeScript/issues/12184
// Instead, we have chosen to return an object { error, response }
export type APIResponse<T> = APISuccessResponse<T> | APIErrorResponse;

export type SuccessResponse<T> = {
  status: number;
  headers: Record<string, string>;
  body: T;
};

export function isSuccessResponse<T>(
  response: unknown
): response is SuccessResponse<T> {
  const maybeSuccessResponse = response as SuccessResponse<T>;
  return !isAPIError(maybeSuccessResponse.body);
}

export type ErrorResponse<T> = {
  status: number;
  headers: Record<string, string>;
  body: T;
  errors?: string[];
};

export function isErrorResponse<T>(
  response: unknown
): response is ErrorResponse<T> {
  const maybeErrorResponse = response as ErrorResponse<T>;
  return isAPIError(maybeErrorResponse.body);
}

export class DecoderError extends TypeError {
  data: ErrorResponse<unknown>;

  constructor(
    message: string,
    { body, errors = [], headers, status }: ErrorResponse<unknown>
  ) {
    super(message);
    this.name = 'DecoderError';
    this.data = { body, errors, headers, status };
  }
}

export class HttpError extends Error {
  data: ErrorResponse<unknown>;

  constructor(
    message: string,
    { body, headers, status }: ErrorResponse<unknown>
  ) {
    super(message);
    this.name = this.constructor.name;
    this.data = { body, headers, status };
  }
}
export type HttpErrorType = HttpError;

export class UnauthorizedError extends HttpError {}
export class UnauthorizedFromAuthError extends UnauthorizedError {}
export class UnknownResponseError extends HttpError {}
export type UnauthorizedErrorType = UnauthorizedError;
export type UnauthorizedFromAuthErrorType = UnauthorizedFromAuthError;

async function decode<T>(response: Response, decoder: t.Decoder<unknown, T>) {
  const responseBody = await response.text();

  let payload: unknown;

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    payload = JSON.parse(responseBody);
  } catch (_) {
    payload = responseBody;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const headers = Array.from(response.headers.entries()).reduce<
    Record<string, string>
  >((accum, [key, value]) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    accum[key] = value;
    return accum;
  }, {});

  const { status, statusText } = response;
  try {
    return pipe(
      decoder.decode(payload),
      (decoded) => {
        if (isLeft(decoded)) {
          const maybeDecodedAPIError = APIError.decode(payload);
          if (isRight(maybeDecodedAPIError)) {
            return maybeDecodedAPIError;
          }

          if (PaymentsAPIError.is(payload)) {
            const payloadDetails = payload.details[0];
            return right({
              code: payload.code,
              domain: payloadDetails?.domain ?? '',
              message: payload.message,
              metadata: payloadDetails?.metadata ?? {},
              reason: payloadDetails?.reason ?? '',
            });
          }
        }
        return decoded;
      },
      map((body: T | APIError) => {
        return { status, headers, body };
      }),
      mapLeft((errors) => {
        // This case will either be an invalid API response or some other unexpected exception
        const errInit: ErrorResponse<unknown> = {
          body: payload,
          headers,
          status,
        };

        if (UNAUTH_STATUSES.includes(status)) {
          const { pathname } = new URL(response.url);
          if (pathname.startsWith('/api/auth')) {
            return new UnauthorizedFromAuthError(statusText, errInit);
          }
          return new UnauthorizedError(statusText, errInit);
        } else if (status >= 400) {
          return new UnknownResponseError(statusText, errInit);
        }

        const errorsFormatted = formatValidationErrors(errors);
        errInit.errors = errorsFormatted;
        return new DecoderError(errorsFormatted.join('\n'), errInit);
      })
    );
  } catch (err) {
    const finalError = err instanceof Error ? err : new Error('Unknown error');
    return Promise.resolve(left<Error, SuccessResponse<T>>(finalError));
  }
}

export type FetchOptions<Outgoing> = {
  method?: Method;
  body?: Outgoing;
  headers?: Record<string, string | null>;
};

export type APIFetch = <Incoming, Outgoing = void>(
  url: string,
  opts: FetchOptions<Outgoing>,
  decoder: t.Decoder<unknown, Incoming>
) => Promise<APIResponse<Incoming>>;

let authToken: string | null;
let appID: string | null;
let merchantID: string | null;
let programID: string | null;

/**
 * Returns an apiFetch function that makes type safe API calls.
 */
export const useFetch = (): APIFetch => {
  const { merchantID: hookMerchantID } = useMerchantID();
  const { programID: hookProgramID } = useProgramID();
  const { appID: hookAppID } = useAppID();
  const { authToken: storeAuthToken, setAuthToken } = useAuthTokenStore();

  // TODO: This is a hack to get around the fact that the appID, merchantID and
  // programID are being reset to null
  if (hookAppID) {
    appID = hookAppID;
  }
  if (hookMerchantID) {
    merchantID = hookMerchantID;
  }
  if (hookProgramID) {
    programID = hookProgramID;
  }

  // Need to copy the authToken from the store to the local variable
  // so that newly received tokens can be used before the next render
  // We set the local `authToken` var below when we receive an
  // authorization response header
  if (storeAuthToken) {
    authToken = storeAuthToken;
  }

  /**
   * Makes a type safe API call with runtime type checking.
   * Incoming type is the expected API Response type.
   * Outgoing is the API request type.
   *
   * @param url - the url of the request
   * @param requestOptions - request options
   * @param requestOptions.method - POST, PUT, or GET
   * @param requestOptions.body - The body of the request for POST or PUT
   * @param requestOptions.headers - Additional headers to be sent with the request
   * @param decoder - The io.type object for the expected response
   *
   * @returns the validated API response object.
   * @throws {@link UnauthorizedError} when user is not authorized to access the URL requested
   * @throws {@link UnauthorizedFromAuthError} when user is not authorized to access
   * an auth endpoint
   * @throws {@link DecoderError} when the response does not match the expected Incoming type.
   * @throws {@link UnknownResponseError} when the response cannot be decoded as
   * a success or an API Error response.
   *
   */
  const apiFetch = async function apiFetch<Incoming, Outgoing = void>(
    url: string,
    { method = 'POST', body, headers = {} }: FetchOptions<Outgoing>,
    decoder: t.Decoder<unknown, Incoming>
  ): Promise<APIResponse<Incoming>> {
    // If no content-type is set,
    // and there is no body or body is not a FormData object,
    // set content-type to application/json
    const isJSONPayload = body && !(body instanceof FormData);
    const requestHeaders: Headers = new Headers();

    requestHeaders.append('Authorization', `${authToken}`);

    if (appID) {
      requestHeaders.append('X-Bread-APP-ID', appID);
    }

    // Used to ensure merchantID in xProps matches decoded jwt in proxy
    if (merchantID) {
      requestHeaders.append('X-Bread-Merchant-ID', merchantID);
    }

    if (isJSONPayload) {
      requestHeaders.append('Content-Type', 'application/json');
    }

    // Callers of apiFetch may set/override above headers (e.g. authorization, content-Type)
    for (const key in headers) {
      const value = headers[key];

      if (value) {
        requestHeaders.set(key, value);
      } else {
        requestHeaders.delete(key);
      }
    }

    if (programID) {
      requestHeaders.append('X-Bread-Program-ID', programID);
    }

    // fetch will throw TypeError('Only absolute URLs are supported')
    const reqUrl = RE_URL_PROTOCOL.test(url)
      ? url
      : new window.URL(url, window.location.href).toString();

    const reqInit: RequestInit = { method, headers: requestHeaders };

    // POST REQUEST accepted types: 'application/json' | 'multipart/form-data'
    // If body is present on the request and the content-type is not json
    // Content-type should not be set for 'multipart/form-data
    if (isJSONPayload) {
      reqInit.body = JSON.stringify(body);
    } else if (body instanceof FormData) {
      reqInit.body = body;
    }

    reqInit.credentials = 'include';

    const response = await window.fetch(reqUrl, reqInit);
    const result = await decode<Incoming>(response, decoder);

    if (isLeft(result)) {
      throw result.left;
    }

    // All 401 and 403s need to throw to bypass handlers used in onSubmit
    // except for auth handlers which use try/catch
    if (UNAUTH_STATUSES.includes(result.right.status)) {
      const errInit: ErrorResponse<unknown> = {
        body: result.right.body,
        headers: result.right.headers,
        status: result.right.status,
      };
      const { pathname } = new URL(response.url);

      if (pathname.startsWith('/api/auth')) {
        throw new UnauthorizedFromAuthError(response.statusText, errInit);
      }

      throw new UnauthorizedError(response.statusText, errInit);
    }

    if (isSuccessResponse<Incoming>(result.right)) {
      const { authorization } = result.right.headers;

      if (authorization) {
        authToken = authorization;
        setAuthToken(authorization);
      }

      return { error: null, response: result.right };
    } else if (isErrorResponse<APIError>(result.right)) {
      return { error: result.right.body, response: null };
    }

    throw new UnknownResponseError('Unknown fetch result type', {
      body: result.right.body,
      headers: result.right.headers,
      status: result.right.status,
    });
  };
  return apiFetch;
};
