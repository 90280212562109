import React, { createContext, useContext, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { classNames } from '../lib/classNames';
import { FCWithChildren } from '../lib/types';
import { ChevronDown } from './svgs/ChevronDown';
import { SVGIcon } from './svgs/svgHelpers';

interface SectionProps {
  icon?: SVGIcon;
  title: string;
  summary?: string;
  allowWrapping?: boolean;
}

type AccordionContext = {
  openSection: string;
  toggleOpen: (id: string) => void;
};

export const AccordionContext = createContext<AccordionContext>({
  openSection: '',
  toggleOpen: function () {
    /*noop */
  },
});

function useSectionsContext() {
  return useContext<AccordionContext>(AccordionContext);
}

export const Accordion: FCWithChildren = ({ children }) => {
  const [openSection, setOpenSection] = useState('');
  const toggleOpen = (id: string) => {
    setOpenSection(id === openSection ? '' : id);
  };

  return (
    <div data-testid="accordion">
      <AccordionContext.Provider value={{ openSection, toggleOpen }}>
        {children}
      </AccordionContext.Provider>
    </div>
  );
};

export const Section: FCWithChildren<SectionProps> = ({
  children,
  icon: Icon,
  summary,
  title,
  allowWrapping = true,
}) => {
  const id = useMemo(() => v4(), []);
  const { openSection, toggleOpen } = useSectionsContext();
  const isOpen = id === openSection;

  const slug = title
    .toLowerCase()
    .split(' ')
    .join('-')
    .replace(/[^a-zA-Z0-9-]/gi, '');

  return (
    <>
      <button
        id={`section-header-${slug}`}
        type="button"
        data-testid={`section-header-${slug}`}
        data-collapsed={!isOpen}
        aria-controls={`section-content-${slug}`}
        aria-expanded={isOpen}
        className={classNames(
          'flex items-center rounded-sm p-3 space-x-3 mb-4 hover:bg-gray-200 transition-shadow w-full text-left',
          isOpen ? 'bg-gray-200 shadow' : 'bg-gray-100'
        )}
        onClick={() => toggleOpen(id)}
      >
        {Icon && <Icon className="w-5 shrink-0" aria-hidden="true" />}

        <div
          className={`flex-grow text-sm font-bold${
            allowWrapping ? ' whitespace-normal' : ' whitespace-nowrap'
          }`}
        >
          {title}
        </div>
        {summary && (
          <div className="text-sm font-normal truncate shrink">{summary}</div>
        )}
        <ChevronDown
          className={`w-4 flex-shrink-0 transition-transform duration-500 fill-current text-blackberry ${
            isOpen ? 'transform-gpu rotate-180' : ''
          }`}
          aria-hidden="true"
        />
      </button>
      <div
        id={`section-content-${slug}`}
        role="region"
        aria-labelledby={`section-header-${slug}`}
        data-testid={`dropdown-${slug}`}
        className={`transition-all overflow-hidden duration-500 ease-out ${
          isOpen ? 'max-h-screen visible' : 'max-h-0 invisible'
        }`}
      >
        <div className="text-sm px-2 pb-4">{children}</div>
      </div>
    </>
  );
};
